export default {
  icon: {
    get() {
      return this.$store.getters["icon"];
    },
  },
  title: {
    get() {
      return this.$store.getters["title"];
    },
  },
  currentAppId: {
    get() {
      return this.$store.getters["userStore/currentAppId"] || 0;
    }
  },
  currentAppName: {
    get() {
      return this.$store.getters["userStore/currentAppName"] || '- 未选择 -';
    }
  },
  sessionId: {
    get() {
      return this.$store.getters['userStore/sessionId'];
    }
  },
  noApp: {
    get() {
      return this.currentAppId === 0;
    }
  }
}