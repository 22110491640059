<template>
  <div class="cash-orders">
    <div class="top-btns">
      <el-button
        type="primary"
        size="mini"
        style="margin-bottom: 1rem"
        @click="doExport"
        >导出数据表</el-button
      >
      <p>
        上传转账结果文件
      </p>
      <file-upload
        class="upload-control"
        top-folder="/cash-order-results"
        @upload-success="uploadSuccess"
      />
    </div>

    <table ref="excel-table" class="excel-table" cellspacing="1">
      <thead>
        <tr>
          <td style="text-align: center" colspan="5">
            支付宝批量付款文件模板（前面两行请勿删除）
          </td>
        </tr>
        <tr>
          <td>序号（必填）</td>
          <td>收款方支付宝账号（必填）</td>
          <td>收款方姓名（必填）</td>
          <td>金额（必填，单位：元）</td>
          <td>备注（选填）</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in cashOrderList" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ row.alipayAccount }}</td>
          <td>{{ row.alipayRealName }}</td>
          <td>{{ row.money }}</td>
          <td>{{ `提现单号:${row.remark}` }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import FileUpload from "@/components/FileUpload";
import {FAST_WXCMS_URL} from '@/utils/request'
import commonComputeds from '@/utils/commonComputeds';
export default {
  name: "CashOrders",
  data() {
    return {
      cashOrderList: [],
    };
  },
  computed: {
    ...commonComputeds
  },
  methods: {
    async uploadSuccess(excelId) {
      try {
        await this.$http.doApi("fw-cms-cash-order-importPayResult", {
          excelId: excelId,
        });
        this.$message.success("操作成功");
        this.loadData();
      } catch (e) {
        console.log(e);
      }
    },
    doExport() {
      window.open(FAST_WXCMS_URL + '/download.do?apiKey=fw-cms-cash-order-downloadExcel&sessionId=' + this.sessionId, '_blank');
    },
    loadData() {
      this.$http
        .doApi(
          "fw-cms-cash-order-exportWaitPayList",
          { pageNo: 1, pageSize: 0 },
          {}
        )
        .then((data) => {
          this.cashOrderList = data.rows;
        });
    },
  },
  async created() {
    this.loadData();
  },
  components: {
    FileUpload,
  },
};
</script>

<style lang="less">
.cash-orders {
  padding: 1rem;

  .excel-table {
    background-color: #cccccc;
    width: 100%;
    td {
      background-color: white;
    }
  }
}
</style>