<template>
  <el-upload
    ref="upload"
    :action="upyunUrl + '/' + bucket"
    :multiple="false"
    :data="uploadData"
    :auto-upload="false"
    :before-upload="beforeUpload"
    :on-success="uploadSuccess"
  >
    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
    <el-button
      style="margin-left: 10px"
      size="small"
      type="success"
      @click="submitUpload"
      >上传到服务器</el-button
    >
    <div slot="tip" class="el-upload__tip">只能上传excel文件</div>
  </el-upload>
</template>

<script>
export default {
  name: "file-upload",
  props: {
    value: {
      type: [String, Number],
    },
    topFolder: {
      type: String,
    },
  },
  data() {
    return { upyunUrl: "", bucket: "", domain: "", uploadData: {} };
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    async uploadSuccess(res) {
      let { val } = await this.$http.doApi("fw-bzh-saveFile", {}, res);
      this.$emit("input", val);
      this.$emit("upload-success", val);
    },
    async beforeUpload() {
      let data = await this.$http.doApi(
        "fw-bzh-getPolicyAndAuthorization",
        {
          topFolder: this.topFolder,
        },
        {}
      );
      this.uploadData = data;
    },
    async loadUpyunConfig() {
      let configData = await this.$http.doApi("fw-bzh-getUpCloudConfig");
      this.upyunUrl = configData.url;
      this.bucket = configData.bucket;
      this.domain = configData.domain;
    },
  },
  created() {
    this.loadUpyunConfig();
  },
};
</script>

<style>
</style>